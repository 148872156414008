<script setup lang="ts">
// Get the current route
const route = useRoute()
const baseUrl = useRequestURL().origin

// Construct the canonical URL dynamically
const canonicalUrl = baseUrl + route.fullPath

// Set the head elements, including the canonical link
useHead({
  link: [
    {
      rel: 'canonical',
      href: canonicalUrl
    }
  ],
  bodyAttrs: {
    class: 'dark:bg-gray-950'
  }
})
</script>

<template>
  <div class="h-screen flex items-center justify-center overlay background">
    <div class="gradient" />

    <UButton label="Home" to="/"
      class="bg-purple-600 hover:bg-purple-700 text-white rounded-full absolute top-4 px-4 py-2 transition-colors duration-200" />

    <div class="text-purple-900">
      <slot />
    </div>
  </div>
</template>

<style scoped>
.gradient {
  position: absolute;
  inset: 0;
  pointer-events: none;
  background: radial-gradient(50% 50% at 50% 50%, rgb(var(--color-primary-500) / 0.25) 0, #FFF 100%);
}

.dark {
  .gradient {
    background: radial-gradient(50% 50% at 50% 50%, rgb(var(--color-primary-400) / 0.1) 0, rgb(var(--color-gray-950)) 100%);
  }

  .overlay {
    background-image:
      linear-gradient(to right, rgb(var(--color-gray-900)) 0.5px, transparent 0.5px),
      linear-gradient(to bottom, rgb(var(--color-gray-900)) 0.5px, transparent 0.5px);
  }
}

.overlay {
  background-size: 100px 100px;
  background-image:
    linear-gradient(to right, rgb(var(--color-gray-200)) 0.5px, transparent 0.5px),
    linear-gradient(to bottom, rgb(var(--color-gray-200)) 0.5px, transparent 0.5px);
}
</style>
